<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "全國災害情報",
      items: [
        {
          text: "災害通報中心",
          href: "/disaster-list",
        },
        {
          text: "全國災害情報",
          active: true,
        }
      ],
      typeOptions: [],
      searchData: {
        word: '',
        type: 0,
        status: 0,
      },
      selectedStatus: [0, 1, 2, 3],
      selectedType: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: 'id',
      fields: [
        {
          key: "id",
          label: "#",
          // sortable: true,
        },
        {
          key: "sender_name",
          label: "發布單位",
          // sortable: true,
          class: 'text-nowrap'
        },
        {
          key: "send_time",
          label: "發布時間",
          // sortable: true,
          class: 'text-nowrap center'
        },
        {
          key: "effective_time",
          label: "生效時間",
          // sortable: true,
          class: 'text-nowrap center'
        },
        {
          key: "expires_time",
          label: "到期時間",
          // sortable: true,
          class: 'text-nowrap center'
        },
        {
          key: "event_title",
          label: "事件項目",
          // sortable: false,
          class: 'pre-wrap left'
        },
      ],
    };
  },
  watch: {
    '$root.needReloadDisasterAlertStatus' (_status) {
      if (!_status) {
        return
      }

      this.reloadTable()
    }
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted () {
    this.reloadTable()
    this.getDisasterUnitList()
  },
  created () {
    this.getDisasterUnitList()
  },
  methods: {
    getDisasterUnitList () {
      this.$root.apis.getDisasterUnitList(this.processUnitList)
    },
    processUnitList (_response) {
      let vm = this
      let units = _response.body.data
      console.log('=> 獲得單位清單:', units)
      vm.typeOptions = [{ id: 0, name: "全部" }]
      units.forEach(function (_unit) {
        vm.typeOptions.push({
          id: _unit.name,
          name: _unit.name
        })
      })
    },
    reloadTable () {
      this.$refs.inquiryTable.refresh()
    },
    getAlertList (_table, _callback) {
      const vm = this

      const searchParams = {
        type: this.searchData.type ?? null,
        per_page: this.perPage || 10,
        page: this.currentPage || 1,
        sort: this.sortDesc ? 'desc' : 'asc',
        sort_by: this.sortBy || 'id',
      }

      this.$root.apis.getAlertList(
        searchParams,
        async function (_response) {
          let result = _response.body.data
          vm.currentPage = result.page
          vm.totalRows = result.total
          vm.perPage = result.per_page

          // 整理資料
          let tableData = []
          await result.items.forEach(function (_item, _itemIndex) {
            _item.id = (_itemIndex + 1)
            tableData.push(_item)
          })

          if (typeof _callback === 'function') {
            vm.tableData = await tableData
            console.log('=> 告警清單: ', vm.tableData)
            _callback(vm.tableData)
          }
        },
        function (_error) {
          console.error(_error.body.data)
          if (typeof _callback === 'function') {
            _callback([])
          }
        }
      )
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeType (_type) {
      // check all
      if (_type.includes(0)) {
        this.selectedType = this.typeOptions.map(_type => _type.id)
        this.searchData.type = 0
        // uncheck all
      } else if (this.searchData.type === 0 && !_type.includes(0)) {
        this.selectedType = []
        this.searchData.type = null
        // check others
      } else {
        this.selectedType = _type.length > 0 ? _type.slice(-1) : []
        this.searchData.type = this.selectedType[0] || null
      }
      this.reloadTable()
    },
    formatContent (_content) {
      if (!_content || _content === '') return null
      const row1 = _content.slice(0, 10)
      const row2 = _content.slice(10, 20)
      if (row2) {
        return [row1, row2.slice(0, 8) + '...']
      }
      return row1.length > 8 ? [row1.slice(0, -2) + '...'] : [row1]
    },
  },
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title" :items="items")
  .row
    .col-lg-12.pl-2
      .header-row.mb-3
        .checkbox-group.col-lg-12.mt-3
          b-form-group
            b-form-checkbox-group#checkbox-group-2(
              :checked="selectedType"
              @input="changeType($event)"
              name="type_checkbox"
              )
              b-form-checkbox(
                v-for="_type in typeOptions" 
                :key="_type.id" 
                :value="_type.id"
              ) {{_type.name}}
    //- 表格
    .col-lg-12.mt-2.ml-1
      .card
        .card-body        
          p 資料來源: 國家災害防救科技中心
          //- Table
          .row
            .col-12
              b-table(
                head-variant="light"
                ref="inquiryTable"
                :items="getAlertList",
                :fields="fields",
                responsive,
                :per-page="perPage",
                :current-page="currentPage",
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc",
                :filter="filter",
                :filter-included-fields="filterOn",
                @filtered="onFiltered"
              )

                template(v-slot:cell(sender_name)="data")
                  span(v-if="data.item.sender_name") {{ data.item.sender_name }}
                  .text-center(v-else) -

                template(v-slot:cell(send_time)="data")
                  span {{ ( data.item.send_time ) ? data.item.send_time : '-' }}

                template(v-slot:cell(effective_time)="data")
                  span {{ ( data.item.effective_time ) ? data.item.effective_time : '-' }}

                template(v-slot:cell(expires_time)="data")
                  span {{ ( data.item.expires_time ) ? data.item.expires_time : '-' }}

                template(v-slot:cell(event_title)="data")
                  .text-left(v-if="data.item.event_title")
                    b 類型: 
                    span.event_type {{ data.item.event_type }}, 
                    b 事件: 
                    span {{ data.item.event_title }}
                    hr
                  b 內容: 
                  span {{ data.item.event_content }}
                  .text-left(v-if="data.item.event_area")
                    hr
                    b 影響範圍: 
                    span(v-for="(area , index) in data.item.event_area") 
                      span(v-if="index > 0") ,
                      span {{ area.areaDesc }}

          .row.mb-md-2
            .col-6
              div(id="tickets-table_length", class="dataTables_length")
                label.d-inline-flex.align-items-center Show
                  b-form-select.mr-1.ml-1(v-model="perPage", size="sm", :options="pageOptions") 
                  | entries
            .col
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
<style lang="sass" scoped>
.row
  .header-row
    .header-title
      font-size: 24px
      font-weight: 700
    .header-box
      display: flex
      justify-content: space-between
      .form-group
        display: flex
        align-items: center
  .search-box
    width: 16rem
    position: relative
    #tip
      padding: 0.5rem 2rem
      background-repeat: no-repeat
      background-position: 10px 7px
      line-height: 4rem
    .fa-search
      position: absolute
      top: 35%    
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
  
.text-status-warning
  color: #FF846E


::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  &.delete
    color: #333333

.text-start
  text-align: start

.text-nowrap
  white-space: nowrap

span.event_type
  margin-right: 15px
hr
  margin: 5px 0px
</style>
